  #wrapper{
    perspective: 1000px;
  }

  #phone_1{
    width: 300px; 
    height: 550px;
  }
  
  .phone {
    /*border: 40px solid #ddd;
    border-width: 55px 7px;
    border-radius: 40px;
    margin: 50px auto;
    overflow: hidden;
    transition: all 0.5s ease;*/

    border: 20px solid #ddd;
    border-width: 35px 7px;
    border-radius: 20px;
    margin: 50px auto;
    overflow: hidden;
    transition: all 0.5s ease;
  }
  
  .phone iframe {
    border: 0;
    width: 100%;
    height: 100%;
  }
  /*Different Perspectives*/
  
  .phone.view_1 {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(-50deg);
    box-shadow: -3px 3px 0 #BBB, -6px 6px 0 #BBB, -9px 9px 0 #BBB, -12px 12px 0 #BBB, -14px 10px 20px #666;
  }
  
  .phone.view_2 {
    transform: rotateX(0deg) rotateY(-60deg) rotateZ(0deg);
    box-shadow: 5px 1px 0 #BBB, 9px 2px 0 #BBB, 12px 3px 0 #BBB, 15px 4px 0 #BBB, 0 7px 20px #999;
  }
  
  .phone.view_3 {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    box-shadow: 0px 3px 0 #BBB, 0px 4px 0 #BBB, 0px 5px 0 #BBB, 0px 7px 0 #BBB, 0px 10px 20px #666;
  }
  /*Controls*/
  
  #controls {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 0.9em;
    color: #333;
  }
  
  #controls div {
    margin: 10px;
  }
  
  #controls div label {
    width: 120px;
    display: block;
    float: left;
  }
  
  #views {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 200px;
  }
  
  #views button {
    width: 198px;
    border: 1px solid #bbb;
    background-color: #fff;
    height: 40px;
    margin: 10px 0;
    color: #666;
    transition: all 0.2s;
  }
  
  #views button:hover {
    color: #444;
    background-color: #eee;
  }

  @media (min-width:800px) and (max-width:1200px) {
    .phone.view_3 {
      transform: rotateX(0deg) rotateY(-60deg) rotateZ(0deg);
      box-shadow: 5px 1px 0 #BBB, 9px 2px 0 #BBB, 12px 3px 0 #BBB, 15px 4px 0 #BBB, 0 7px 20px #999;
    }
  }

  @media (max-width:800px) {
    .phone.view_3 {
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
      box-shadow: 0px 3px 0 #BBB, 0px 4px 0 #BBB, 0px 5px 0 #BBB, 0px 7px 0 #BBB, 0px 10px 20px #666;
    }
  }
  
  @media (min-width:800px) and (max-width:900px) {
    #wrapper {
      transform: scale(0.8, 0.8);
    }
  }
  
  @media (max-width:800px) {
    #wrapper {
      transform: scale(1, 1); //scale(0.6, 0.6);
    }
  }
  
  @media (max-width:500px) {
    #wrapper {
      transform: scale(1, 1) // scale(0.4, 0.4);
    }
  }